<template>
    <div id="#modalOTPViewcontent">
        <div id="modalOTPView">
            <div class="text-center" style="padding-bottom: 20px;">
                <P style="margin-top: 10px; font-size: 15px;">Enter one time password (OTP) that we sent to your mobile number <br> {{buyerMobile}}</p>

                <label id="changeNo" style="font-size: 12px; color: gray;" v-if="countdownseconds === 0"><a @click="windowMobileChange(true)"><li class="fa fa-pencil"></li> Mobile Number</a></label><br />
                <label class="control-label" v-if="(countdownseconds > 0 ) && !showNewMobile"><label style="color: blue;">{{ countdownseconds }}</label> seconds</label><br />
                <span style="color: red" v-if="countdownseconds > 0 && !showNewMobile">Please wait till the countdown ends to change the number.</span>
                <span v-if="showNewMobile">
                    <VuePhoneNumberInput  @input="validatePhone(newmobile)" v-model="newmobile" default-country-code="LK" no-example :style="changeColor"  />
                    <button
                    @click="handleResetMobile()"
                    :disabled="newmobile == '' || !mobileValid"
                    id="ProseedBtn" class="btn btn-block buyerreg-btn">
                        Reset Mobile Number
                    </button>
                    <button
                    @click="windowMobileChange(false)" 
                    id="ProseedBtn" class="btn btn-block buyerreg-btn">
                       Cancel
                    </button>

                </span>
                
            </div>
            
            <span v-if="!showNewMobile">
            <div class="form-group" style="padding-bottom: 20px;">
                <label for="pwd">OTP code &nbsp;</label>
                <input v-model="obj.code" type="number" class="form-control" id="pwd">
            </div>
            
            <b-row  v-if="countdownseconds <= 0" style="padding-bottom: 20px; display: flex; justify-content: center;">
                <b-col md="4">
                    <button @click="resendOtp" style="border-radius: 3px;" class="btn btn-primary btn-block buyerreg-btn">Resend</button>
                </b-col>
            </b-row>
            <button  v-if="countdownseconds > 0" @click="create" style="border-radius: 3px;" class="btn btn-primary btn-block buyerreg-btn">Verify Mobile Number</button>
                <div class="col-md-12" style="text-align:center; margin-top:10px;">
                    <a  @click="cancelOTP()">Go Back To Login/Register</a>
                </div>
                <span style="color: red">Login with your mobile number for the first time and verify your email address in the profile section.</span>
            
            </span> 
            
            <!-- <div class="text-center">
                <p style="font-size: 8px;margin-top: 10px">By loggging in you agree to the Supiripola.lk user <router-link :to="'/terms-and-conditions'" target="_blank">Terms & Conditions</router-link> and <router-link target="_blank" :to="'/privacy_policy'">Privacy Policy</router-link></p>
            </div> -->
        </div>
    </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
    mixins: [mixinAuth],
    components: { 
        VuePhoneNumberInput
    },
    props: {
        buyerId: {
            type: String,
            default: false
        },
        buyerMobile: {
            type: String,
            default: false
        },
        OTPReSubmit: {
            type: Number,
            default: false
        },
        actionType: {
            type: String,
            default: false
        }
    },
    data() {
        return {
            countdownseconds: 0,
            blockresendopt: true,
            obj: {
                _id: "",
                code: ""
            },
            newmobile: '',
            showNewMobile: false,
            mobileValid: false,
            changeColor: {
                border: "1px solid #ccc"
            },
            changeColor1: {
                border: "1px solid #ccc"
            },
            downloadTimer: ''
        }
    },
    watch: {
        buyerId: {
            handler(id) {
                if (id) {
                    this.obj._id = id;
                } else {
                    this.obj._id = localStorage.getItem("OTP_Buyer_Id");
                }
            },
            immediate: true,
            deep: true
        },
        // OTPReSubmit: function(newVal, oldVal) { // watch it
        //   this.countdownCal(300);
        // },
        countdownseconds: function(val) {
            if (val < 0) {
                this.blockresendopt = false;
            } else {
                this.blockresendopt = true;
            }
        }
    },
    created() {
        this.countdownCal(90);
    },
    methods: {
        cancelOTP(){
            this.$parent.backToLogin();
        },
        windowMobileChange(val){
            if(val){
                this.showNewMobile = true;
                this.countdownCal(0);
            } else {
                this.showNewMobile = false;
                this.countdownCal(90);
            }
            
        },
        handleResetMobile: async function() {
            try {
                let obj = {
                    "_id": localStorage.getItem("OTP_Buyer_Id"),
                    "mobile": this.newmobile
                }
                
                // For register OTP view phone change
                // if(this.actionType=='register'){
                //     let response = await this.buyerMobileReset(obj)
                // }
                // For login OTP view phone change
                // if(this.actionType=='login'){
                //      let response = await this.buyerMobileResetSathosa(obj)
                // }
                
                let response = await this.buyerMobileResetSathosa(obj);

                this.showNewMobile = false;
                // this.resendOtp();
                this.$parent.changedNumer(this.newmobile);
            } catch (error) {
                this.countdownCal(0);
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: error.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        validatePhone(key) {
            if (key.length == 9) {
                this.changeColor.border = "1px solid red";
                this.mobileValid = true;
            } else if (key.length === 10 || key.length < 10) {
                this.changeColor.border = "1px solid #ccc";
                this.mobileValid = false;
            }
        },
        resendOtp: async function() {
            try {
                // console.log("otp")
                // console.log(this.obj._id)
                let res = await this.buyerMobileResend(this.obj._id);
                if (res.data.success === 1) {
                    this.countdownCal(90);
                }
            } catch (error) {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'Error in resend option',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        create: async function() {
            try {
                this.obj.user_type=2;
                let res = await this.buyerMobileVerify(this.obj);
                if (res.data._id) {
                    localStorage.removeItem("OTP_Buyer_Id");
                    
                    this.$swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Success!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    document.getElementById("tabView").style.display = "block";
                    document.getElementById("modalOTP").style.display = "none";
                    this.$emit("OTPCondition", 1);
                }
            } catch (error) { 
                if(error.data.message == "Code Expired"){
                    this.countdownCal(0)
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Code Expired. Please Resend!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Incorrect OTP code. Please try again!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }             

            }
        },
        countdownCal(duration) {
            clearInterval(this.downloadTimer);
            duration = 90;
            this.countdownseconds = duration;
            let self = this;
            
            this.downloadTimer = setInterval(function(){
                self.countdownseconds -= 1;
                if(self.countdownseconds <= 0){
                    clearInterval(self.downloadTimer);
                }
            }, 1000);
        },
    },
}
</script>
<style>
    #modalOTPView {
        padding: 60px 30px;
        color: black;
    }
    #modalOTPView label{
        font-size: 14px;
        color: black;
    }
    .buyerreg-btn{
        background: #ffd338;
        color: #000000;
    }

    .buyerreg-btn:hover{
        background: #ffd338;
        color: #ffffff;
    }

    #changeNo a:hover{
        color: #ffd338;
    }

    *:focus {
        outline: -webkit-focus-ring-color auto 5px;;
    } 
</style>
