<template>
<!-- Modal -->
  <div v-show="hideModal === 1" class="modal fade" id="BuyerLoginModal" role="dialog">
    <div class="modal-dialog  modal-lg">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px;">
            <div class="row" id="buyerModalContent">
                <div class="col-md-6" id="modalImgCol" :style="activetab === 1 ? 'background-image: url(https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1634204450/cochchi/mercahnt/1634204449335.png)': 'background-image: url(https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1634204313/cochchi/mercahnt/1634204311423.png)'" v-if="!showforgotpass && !showCompleteProfie && !showResetPwOtp">
                    <div style="margin-bottom: 20px;">
                       <!-- <img class="img-responsive"  src="https://res.cloudinary.com/persystance-network/image/upload/v1593918873/slt/portal_user/1593918873513.png" alt=""  style="height: 60%;"> -->
                       <!-- <img class="img-responsive"  src="@/assets/image/cochchi-logo.png" alt=""> -->
                       <!-- <img class="img-responsive"  :src="this.$store.state.whiteLogo" alt="" style="width: 60%"> -->
                        <!-- <p class="text-center" style="padding-top: 10px;">Some text in the modal.</p> -->
                    </div>
                </div>
                <div class="col-md-12" style="display: inline-block;" v-if="showforgotpass && !showCompleteProfie">
                    <div>
                        <button type="button" style="font-size: 35px; font-weight: 500;right: 30px; top: 15px; position: absolute; z-index:100;" data-dismiss="modal" class="close" @click="closeModal()">&times;</button>
                    </div>
                    <BuyerForgotPassword v-bind:resettype="resettype" />
                </div>
                <div class="col-md-12" style="display: inline-block;" v-if="showCompleteProfie && !showResetPwOtp">
                    <div>
                        <button type="button" style="font-size: 35px; font-weight: 500;right: 30px; top: 15px; position: absolute; z-index:100;" data-dismiss="modal" class="close" @click="closeModal()">&times;</button>
                    </div>
                    <CompleteProfileModal v-bind:resettype="resettype" />
                </div>
                <div class="col-md-12" style="display: inline-block;" v-if="showResetPwOtp">
                    <div>
                        <button type="button" style="font-size: 35px; font-weight: 500;right: 30px; top: 15px; position: absolute; z-index:100;" data-dismiss="modal" class="close" @click="closeModal()">&times;</button>
                    </div>
                    <BuyerModalPasswordRestOTP v-bind:resettype="resettype"  :buyerMobile="mobileNo" :resetPwResponse="resetPwResponse"/>
                </div>
                <div class="col-md-6 col-sm-12"  v-if="!showforgotpass && !showCompleteProfie &&  !showResetPwOtp">
                    <div>
                        <button type="button" style="font-size: 35px; font-weight: 500;right: 30px; top: 15px; position: absolute;" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div id="tabView" style="padding: 20px;" >
                        <ul class="nav nav-tabs" style="display: flex; flex-direction: row; justify-content: center;">
                            <li v-on:click="activetab=1" :class=" activetab === 1 ? 'active' : '' " ><a style="font-size:13px;" data-toggle="tab" href="#SignIn">Sign in</a></li>
                            <li v-on:click="activetab=2" :class=" activetab === 2 ? 'active' : '' " ><a style="font-size:13px;" data-toggle="tab" href="#CyberMall">Join now</a></li>
                        </ul>
                        <div class="tab-content scopedClass">
                            <div  v-if="activetab === 1" id="SignIn" class="tab">
                                <SignInTab @OTPReSubmit="OTPReSubmit" @closeModal="closeModal" @buyerIdOTP="buyerIdOTP"/>
                            </div>
                            <div  v-if="activetab === 2" id="CyberMall" class="tab">
                                <NewCyberMallTab @OTPReSubmit="OTPReSubmit" @closeModal="closeModal" @buyerIdOTP="buyerIdOTP"/>
                            </div>
                        </div>         
                    </div>
                    <div id="modalOTP">
                        <BuyerModalOTPView ref="optview" @OTPCondition="OTPCondition" :OTPReSubmit="OTPReSubmit" :buyerMobile="buyerMobile" :buyerId="buyerId" :actionType="actionType"/>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewCyberMallTab from '@/components/buyer/Buyer_modal/BuyerModalNewCyberMallTab.vue'
import SignInTab from '@/components/buyer/Buyer_modal/BuyerModalSignInTab.vue'
import BuyerModalOTPView from '@/components/buyer/Buyer_modal/BuyerModalOTPView.vue'
import BuyerModalPasswordRestOTP from '@/components/buyer/Buyer_modal/BuyerModalPasswordRestOTP.vue'
import BuyerForgotPassword from '@/components/common/modals/forgotPassword.vue'
import CompleteProfileModal from '@/components/common/modals/CompleteProfileModal.vue'
import mixinAuth from "@/mixins/buyer/APIAuth";

export default {
    mixins:[mixinAuth],
    components: {
        NewCyberMallTab,
        CompleteProfileModal,
        BuyerModalPasswordRestOTP,
        SignInTab,
        BuyerModalOTPView,
        BuyerForgotPassword
    },
    data(){
        return {
            showResetPwOtp: false,
            showCompleteProfie: false,
            activetab: 1,
            hideModal: 1, 
            showforgotpass: false,
            resettype: '',
            buyerId: "",
            buyerMobile: "",
            mobileNo:"",
            OTPReSubmit: 0,
            timeOTP: 300,
            resetPwResponse: {},
            actionType: ""
        }
    },
    mounted() {
        if (localStorage.getItem("OTP_Buyer_Id")) {
            document.getElementById("tabView").style.display = "none";
            document.getElementById("modalOTP").style.display = "block";  
            this.checkRemainTime()                 
            
        } else {
            document.getElementById("tabView").style.display = "block";
            document.getElementById("modalOTP").style.display = "none";
        }
    },
    created(){

          
    },
    methods: {
        checkRemainTime: async function() { 

            try {
                
                if(localStorage.OTP_Buyer_Id){
                
                    if(localStorage.OTP_Buyer_Id !== ""){
                        let obj = {
                        "_id" : localStorage.OTP_Buyer_Id
                        }

                        let response = await this.putBuyerMobileOTPremain(obj);
                        this.timeOTP = response.time
                        
                        this.$refs.optview.countdownCal(this.timeOTP);
                    }
                } 
            } catch (error) {
                console.log(error);
            } 

        },
        changedNumer(newmobile){
            this.buyerMobile = newmobile;
        },
        showPwResetModal(mobile,obj){
            this.resetPwResponse = obj;
            this.mobileNo = mobile;
            this.showforgotpass = false; 
            this.resettype = "buyer";
            this.showResetPwOtp = true;
             $('#BuyerLoginModal').modal('show');
        },
        closePwResetModal(){
            this.showResetPwOtp = false;
        },
        resetOTPtimer(){
            this.$refs.optview.countdownCal(90);
        },
        showModal() {
            $('#BuyerLoginModal').modal('show');            
            this.$refs.optview.countdownCal(this.timeOTP);
        },
        closeModal(con) {

            if (con === "forgetPass") {
                this.$store.commit("updateResetPassCondition", "reload");
                this.activetab = 1
                $('#BuyerLoginModal').modal('hide')
                this.$router.push('/reset-password').catch(err => {});
                this.hideModal = 0;
            } else {
                this.activetab = 1
                $('#BuyerLoginModal').modal('hide')
                this.hideModal = 0;
            }
        },
        closeModalCompleteProfile() {
            $('#BuyerLoginModal').modal('hide');
        },
        buyerIdOTP(id, mobile, type) {
            this.buyerId = id;
            this.buyerMobile = mobile;
            this.actionType = type;
        },
        OTPCondition(con) {
            this.activetab = 1;
        },
        showForgotPass(show, type){
            if(show){
                this.showforgotpass = true;
                this.resettype = type;
            } else {
                this.showforgotpass = false;
                this.resettype = '';
            }
        },
        backToLogin(){
            localStorage.removeItem("OTP_Buyer_Id");
            document.getElementById("tabView").style.display = "block";
            document.getElementById("modalOTP").style.display = "none";
        }
    },
}
</script>
<style>
#modalOTP {
    display: none;
}
#BuyerLoginModal .modal-header {
    border-bottom: none !important;
}

#BuyerLoginModal .modal-footer  {
    border-top: none !important
}

#BuyerLoginModal .col-md-6 {
    height: 800px;
}
#buyerModalContent {
    margin: 10px;
    box-shadow: 2px 2px 2px 2px lightgray;
    /* border: solid 1px #f2f2f2 */
    /* padding-bottom: 35px; */
}
#BuyerLoginModal .row 
/* #BuyerLoginModal .close {
    font-size: 37px;
    font-weight: 500;
} */
#BuyerLoginModal .nav-tabs {
    border: none !important;
}
#BuyerLoginModal .tab-content {
    padding: 0 !important;
    border: none !important;
}
#BuyerLoginModal .nav-tabs>li.active>a {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 3px solid #5779ae;
    background-color: #f2f2f2;
    color:#5779ae;
}
#BuyerLoginModal .nav-tabs>li>a {
    font-size: 16px;
    color: gray;
}  
#BuyerLoginModal .nav-tabs {
    border-bottom:  none !important;
}
#modalImgCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    background-repeat: no-repeat;
  background-size: cover;
}

#SignIn {
    padding: 60px 10px;
}

@media screen and (max-width: 991px) {
    #modalImgCol {
        display: none !important;
    }
}

@media screen and (max-width: 520px) {
    #BuyerLoginModal .col-md-6 {
        height: auto;
    }
}

@media screen and (max-width: 425px) {
    #buyerModalContent {
        padding-bottom: 60px;
    }

}
@media screen and (max-width: 375px) {
    #buyerModalContent {
        padding-bottom: 80px;
    }
}
@media screen and (max-width: 330px) {
    #buyerModalContent {
        padding-bottom: 100px;
    }
}
@media screen and (max-width: 300px) {
    #buyerModalContent {
        padding-bottom: 200px;
    }
}


</style>