<template>
    <div>
        <div class="row" >
            <div class="col-md-12" style="text-align:center; padding-top: 55px;">
                
                <img src="@/assets/image/user_onboard_image.png" style="margin-left: 25px;">
            
            
            </div>
        </div>
        <div class="row align-col " style="margin-top:20px;">
            <div class="col-md-8"  style="text-align:center;">
                <h3 style="color: black;">Complete your Profile</h3>
                <p style="color: black; margin-top:10px;">To enhance  your user experience on cochchi.lk we 
                    suggest you to update  your address  information  
                    and complete  your profile . This will only take less than one minute.
                </p>
            </div>
        </div>
        <div class="row align-col " style="margin-top:20px;">
            <div class="col-md-8 align-col">
                <button  @click="goToProfile" 
                    style=" width: 150px; background-color: #ffd300;" 
                    class="btn">
                    Go to Profile
                </button>
            </div>
        </div>
        <div class="row align-col " style="margin-top:20px;">
            <div class="col-md-8 align-col">
                <button  @click="skip" 
                    style=" width: 150px; background-color: white;" 
                    class="btn">
                    Skip
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";
import mixinCommon from "@/mixins/APICommon";
import firebase from 'firebase';
export default {
    mixins: [mixinAuth, mixinCommon],
    props: { 
        resettype: {
			type: String
		},
	}, 
    data() {
        return {
            checkMobile: true,
            checkEmail: false,
            form: {
                username: "",
                password: ""
            },
            disableInput: false,
            email: '',
            message: ''
        }
    },
    computed: {
    formIsValid() {
      return (
        this.email
      );
    }
  },
    methods: {
        goToProfile() {
            this.$parent.closeModal();
            this.$router.push({ name: "Buyer Profile" });
        },
        skip() {
            this.$parent.closeModal();
        }
    }
}
</script>
<style>
    .align-col{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #modalsocialrow {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
        padding: 25px 0px;
    }
    #signForm {
        border-bottom: solid 1px #f2f2f2;
        padding-bottom: 43px;
    }
    #signForm label{
        font-size: 14px;
        color: black;
    }

    @media screen and (max-width: 425px) {
        #modalsocialrow img{
            width: 50%;
        }
    }
</style>
