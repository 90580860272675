<template>
    <div id="#buyerModalPasswordRestOTP">
        <div id="modalOTPView">
            <div class="text-center" style="padding-bottom: 20px;">
                <P style="margin-top: 10px; font-size: 15px;">Enter one time password (OTP) that we have sent to your mobile number <br> {{buyerMobile}}</p>

                <!-- <label id="changeNo" style="font-size: 12px; color: gray;"><a @click="windowMobileChange(true)"><li class="fa fa-pencil"></li> Mobile Number</a></label><br /> -->
                <label class="control-label" v-if="countdownseconds > 0"><label style="color: blue;">{{ countdownseconds }}</label> seconds</label>
                <!-- <span v-if="showNewMobile">
                    <VuePhoneNumberInput  @input="validatePhone(newmobile)" v-model="newmobile" default-country-code="LK" no-example :style="changeColor"  />
                    <button
                    @click="handleResetMobile()"
                    :disabled="newmobile == '' || !mobileValid"
                    id="ProseedBtn" class="btn btn-block buyerreg-btn">
                        Send
                    </button>
                    <button
                    @click="windowMobileChange(false)" 
                    id="ProseedBtn" class="btn btn-block buyerreg-btn">
                       Cancel
                    </button>

                </span> -->
                
            </div>
            
            <span v-if="!showNewMobile">
            <div class="form-group">
                <label for="pwd">OTP code <span style="color: red;">*</span> : &nbsp;</label>
                <input oncopy="return false" onpaste="return false" v-model="obj.code" type="number" class="form-control" id="pwd">
            </div>
            <div  class="red-message" v-if="messageCode">{{messageCode}}</div>
            <div class="form-group" >
                <label for="pwd">Password <span style="color: red;">*</span> : &nbsp;</label>
                <input oncopy="return false" onpaste="return false" type="password" v-model="password" @input="obj.password=password"  class="form-control" id="pwd">
            </div>
            <div  class="red-message" v-if="messagePassword">{{messagePassword}}</div>
            <div class="form-group" >
                <label for="pwd">Confirm Password <span style="color: red;">*</span> : &nbsp;</label>
                <input oncopy="return false" onpaste="return false" type="password" v-model="confPassword"  class="form-control" id="pwd">
            </div>
            <label v-show="!passwordValid && form.password !=''">
                <span style="color: grey;font-size:10px;">
                    <span v-for="(vrule,index) in valRuleArray" :key="vrule.index">
                        {{vrule.valRule}} <span v-html="vrule.valIcon"></span>{{valRuleArray.length-1!=index?', ':''}} 
                    </span>
                    </span> 
            </label>
            <!-- <div  class="red-message" v-if="messageConfirmPw">{{messageConfirmPw}}</div> -->
            <b-row  v-if="countdownseconds <= 0" style="padding-bottom: 20px; display: flex; justify-content: center;">
                <b-col md="4">
                    <button @click="resendOtp" style="border-radius: 3px;" class="btn btn-primary btn-block buyerreg-btn">Resend</button>
                </b-col>
            </b-row>
            <button  v-if="countdownseconds > 0" :disabled="!validPassword" @click="create" style="border-radius: 3px; margin-top:20px; margin-bottom:20px;" class="btn btn-block buyerreg-btn">Verify Mobile Number</button>
            <div class="col-md-12" style="text-align:center; margin-top:10px;">
                <a  @click="cancelOTP()">Go Back To Login/Register</a>
            </div>
            
            </span> 
            
            <!-- <div class="text-center">
                <p style="font-size: 8px;margin-top: 10px">By loggging in you agree to the Supiripola.lk user <router-link :to="'/terms-and-conditions'" target="_blank">Terms & Conditions</router-link> and <router-link target="_blank" :to="'/privacy_policy'">Privacy Policy</router-link></p>
            </div> -->
        </div>
    </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";

export default {
    mixins: [mixinAuth],
    components: { 
    },
    props: {
        resetPwResponse: {
            type: Object,
            default: null
        },
        buyerMobile: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            countdownseconds: 0,
            blockresendopt: true,
            obj: {
                _id: "",
                code: "",
                password: ""
            },
            password: "",
            confPassword: "",
            newmobile: '',
            showNewMobile: false,
            mobileValid: false,
            changeColor: {
                border: "1px solid #ccc"
            },
            changeColor1: {
                border: "1px solid #ccc"
            },
            messageCode: "",
            messagePassword: "",
            messageConfirmPw: "",
            validForm: false,
        }
    },
    computed: {
        validPassword(){
            if(this.obj.password && this.obj.code && this.confPassword){
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        countdownseconds: function(val) {
            if (val < 0) {
                this.blockresendopt = false;
            } else {
                this.blockresendopt = true;
            }
        },
        confPassword: function() {
            if( this.obj.password != this.confPassword){
                this.messageConfirmPw = "Please enter the same password";
                this.validForm= false;
            } else {
                this.messageConfirmPw = "";
                this.validForm= true;
            }
        },
        password: function() {
            if( this.obj.password.length < 6 ){
                this.messagePassword = "Please enter a password with minimum 6 digits"
                this.validForm= false;
            } else {
                this.messagePassword = "";
                this.validForm= true;
            }
        }
    },
    created() {
        this.countdownCal(90);
    },
    methods: {
        cancelOTP(){
            this.$parent.closePwResetModal();
        },
        windowMobileChange(val){
            if(val){
                this.showNewMobile = true;
                this.countdownCal(0);
            } else {
                this.showNewMobile = false;
                this.countdownCal(90);
            }
            
        },
        handleResetMobile: async function() {
            try {
                let obj = {
                    "_id": localStorage.getItem("OTP_Buyer_Id"),
                    "mobile": this.newmobile
                }
                let response = await this.buyerMobileReset(obj)
                this.showNewMobile = false;
                this.resendOtp();
                this.$parent.changedNumer(this.newmobile);
            } catch (error) {
                this.countdownCal(0);
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: error.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        validatePhone(key) {
            if (key.length == 9) {
                this.changeColor.border = "1px solid red";
                this.mobileValid = true;
            } else if (key.length === 10 || key.length < 10) {
                this.changeColor.border = "1px solid #ccc";
                this.mobileValid = false;
            }
        },
        resendOtp: async function() {
            try {
                let obj= {
                    "username": ""
                };
                if(this.buyerMobile){
                    obj.username= this.buyerMobile;
                    let response = await this.postBuyerRestPasswordGetOtp(obj); 
                    if (response) {
                        this.resetPwResponse = response;
                        this.countdownCal(90);
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'Error in resent OTP',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        create: async function() {
            try {
                if(this.validForm){
                    this.obj._id= this.resetPwResponse.id;
                    let res = await this.buyerOtpPwresetVerify(this.obj);
                    if (res) {
                        this.$swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Success!',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        this.$parent.closePwResetModal();
                    }
                }
            } catch (error) { 
                if(error.data.message == "Code Expired"){
                    this.countdownCal(0)
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Code Expired. Please Resend!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Incorrect OTP code. Please try again!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }             

            }
        },
        countdownCal(duration) {
            duration = 90;
            this.countdownseconds = duration;
            let self = this;
            clearInterval(downloadTimer);
            var downloadTimer = setInterval(function(){
                self.countdownseconds -= 1;
                if(self.countdownseconds <= 0){
                    clearInterval(downloadTimer);
                }
            }, 1000);
        },
    },
}
</script>
<style>
    .red-message{
        font-size: 12px;
        color: red;
        margin-top: -20px;
    }
    #modalOTPView {
        padding: 60px 30px;
        color: black;
    }
    #modalOTPView label{
        font-size: 14px;
        color: black;
    }
    .buyerreg-btn{
        background: #ffd338;
        color: #000000;
    }

    .buyerreg-btn:hover{
        background: #ffd338;
        color: #ffffff;
    }

    #changeNo a:hover{
        color: #ffd338;
    }

    *:focus {
        outline: -webkit-focus-ring-color auto 5px;;
    } 
</style>
