<template>
    <div>
        <div id="regForm">
            <div class="text-center">
                <P style="margin-top: 10px">New to Cochchi ? Sign Up now and receive exclusive offers and deals</p>
            </div>
            
            <div class="row">
                <div class="col-md-5" style="height: auto !important; padding-right: 3px;">
                    <div class="form-group" style="margin-bottom: 4px;">          
                        <label>First Name <span style="color: red;">*</span></label>
                        <input :style="styleValidFname" v-model="form.first_name" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-md-7" style="height: auto !important;">
                    <div class="form-group"  style="margin-bottom: 4px;">
                        <label>Last Name <span style="color: red;">*</span></label>
                        <input :style="styleValidLname" v-model="form.last_name" type="text" class="form-control">
                    </div>
                </div>
            </div>            
            
            <div class="row"  style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="form-group"  style="margin-bottom: 4px;" >
                        <label>Mobile No <span style="color: red;">*</span> : &nbsp;</label>
                        <VuePhoneNumberInput valid-color="green" color="red" @update="setCountryCode" v-model="mobileNo" @input="validatePhone(mobileNo)" default-country-code="LK" no-example style="height:30px; cursor:auto;" id="mobileInput" type="tel"/>
                        
                    </div>
                    <div v-if="mobileValid === false && mobileNo !== 0 && this.countryCode==94" style="margin-top: 5px; font-size: 12px; color: red; text-align:right;">Enter valid mobile number</div>
                </div>   
            </div>      
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group"  style="margin-bottom: 4px;">
                        <label>Email <span style="color: red;">*</span></label>
                        <input @input="validateEmail(form.email)" :style="changeColor1" v-model="form.email" type="email" class="form-control">
                        <span style="color: red;">{{emailError}}</span>
                    </div>
                </div>   
            </div>   
            <div class="form-group input-password"  style="margin-bottom: 4px;">
                <label>Password <span style="color: red;">*</span> : &nbsp;</label>
                <input oncopy="return false" onpaste="return false" :style="styleValidPassword" v-model="form.password" type="password" class="form-control" @input="validatePassword" ref="inputRegPwd">
                <span class="toggleIcon" @click="togglePwd()" ref="toggleIcon"><i class="fa fa-eye"></i></span>
                <!-- <label v-show="!passwordValid && form.password !=''"><span style="color: red;font-size:10px;">At least 8 characters, include one lowercase and one uppercase letter</span> </label> -->
                <label v-show="!passwordValid && form.password !=''">
                    <span style="color: grey;font-size:10px;">
                        <span v-for="(vrule,index) in valRuleArray" :key="vrule.index">
                           {{vrule.valRule}} <span v-html="vrule.valIcon"></span>{{valRuleArray.length-1!=index?', ':''}} 
                        </span>
                     </span> 
                </label>
            </div>
            <div class="form-group input-password"  style="margin-bottom: 4px;">
                <label>Retype Password <span style="color: red;">*</span> : &nbsp;</label>
                <input oncopy="return false" onpaste="return false" :style="styleValidPasswordRe" v-model="password_two" type="password" class="form-control" @input="valConfPassword()" ref="inputRegPwdRe" :disabled="!passwordValid">
                <span class="toggleIcon" @click="togglePwdRe()" ref="toggleIconRe"><i class="fa fa-eye"></i></span>
                <label v-show="!PasswordReValid && password_two != ''">
                    <span style="color: grey;font-size:10px;">
                        <span v-for="(vrule,index) in valRuleArrayConf" :key="vrule.index">
                           {{vrule.valRule}} <span v-html="vrule.valIcon"></span>{{valRuleArrayConf.length-1!=index?', ':''}} 
                        </span>
                     </span> 
                </label>
            </div>

            <div style="margin-bottom: 10px;">
                <input
                    type="checkbox"
                    v-model="news_feed"
                >
                &nbsp;&nbsp;&nbsp;
                <span style="font-size: 14px;" @click="handleOpenNewTab">I would like to receive exclusive offers and promotional emails
from Cochchi</span>
            </div>
            <div style="margin-bottom: 10px;">
                <input
                    type="checkbox"
                    v-model="termCond"
                >
                &nbsp;&nbsp;&nbsp;
                <span style="font-size: 14px;">I Accept Terms & Conditions by creating an account, you agree to the Cochchi <a href="./TermsAndConditions.html" target="_blank" style="font-size: 14px; color: blue; font-weight: 600;">Terms & Conditions</a> and <a target="_blank" href="./PrivacyPolicy.html" style="color: blue; font-weight: 600; font-size: 14px;">Privacy Policy</a>.
                    <!-- <p style="font-size: 14px;"> <a href="./TermsAndConditions.html" target="_blank" style="font-size: 14px; color: blue; font-weight: 600;">Terms & Conditions</a> and <a target="_blank" href="./PrivacyPolicy.html" style="color: blue; font-weight: 600; font-size: 14px;">Privacy Policy</a>
                    </p> -->
                </span>
            </div>
            <!-- <b-row v-if="formIsValid">
                <b-col md="12">
                    <vue-recaptcha class="pull-right" @verify="verifyEvent" sitekey="6Ldn8sYUAAAAAHeNiLb65O40B511b8PtFPA4fYLY" :loadRecaptchaScript="true"></vue-recaptcha>
                </b-col>
            </b-row> -->
            <button
                @click="proseedClick()"
                :disabled="!formIsValid || (form.first_name.length == 0) || (form.last_name.length == 0)"
                id="ProseedBtn" class="btn btn-block buyerreg-btn">
                    Proceed
                </button>
            <!-- <div class="text-center">
                <p style="font-size: 9px; margin-bottom: -8px; margin-top: 2px">By creating an account, you agree to the Lanka Sathosa user <router-link :to="'/terms-and-conditions'" target="_blank" style="font-size: 12px; color: blue; font-weight: 600;">Terms & Conditions</router-link> and <router-link target="_blank" :to="'/privacy_policy'" style="color: blue; font-weight: 600; font-size: 12px;">Privacy Policy</router-link>
                </p>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-8 col-sm-8" id="modalsocialrow" >
                    <small>Quck Accsess with </small>
                    <a>
                        <img src="assets/image/buyer_modal/google.png" @click="loginWithFirebase(1)" title="Google Login">
                    </a>
                    <a>
                        <img src="assets/image/buyer_modal/facebook.png" @click="loginWithFirebase(2)" title="Facebook Login">
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script>
<script>
import VueRecaptcha from 'vue-recaptcha';
import VuePhoneNumberInput from 'vue-phone-number-input';
import mixinAuth from "@/mixins/buyer/APIAuth";
import firebase from 'firebase';
export default {
    components: { 
        VueRecaptcha,
        VuePhoneNumberInput
    },
    mixins: [mixinAuth],
    data() {
        return {
            news_feed: true,
            termCond: false,
            passwordValid: false,
            PasswordReValid: false,
            emailValid: false,
            mobileValid: false,
            mobileNo: "",
            dpmobile:"",
            emailError: "",
            changeColor: {
                border: "1px solid #ccc"
            },
            changeColor1: {
                border: "1px solid #ccc"
            },
            disable: true,
            showReCapture: true,
            password_two: "",
            form: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                password: "",
                user_type: 2,
                dial_code: "",
                county_code:""
            },
            mobile: "",
            dialCode: "LK",
            countryCode:94,
            valRuleArray: [
                {
                    valRule: 'At least 8 characters',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                },
                {
                    valRule: 'One lowercase letter',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                },
                {
                    valRule: 'One uppercase letter',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                },
                {
                    valRule: 'One special character',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                },
                {
                    valRule: 'One numerical character',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                }
            ],
            valRuleArrayConf: [
                {
                    valRule: "At least 8 characters",
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
                },
                {
                    valRule: "One lowercase letter",
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
                },
                {
                    valRule: "One uppercase letter",
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
                },
                {
                    valRule: "One special character",
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
                },
                {
                    valRule: "Match password",
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
                },
                {
                    valRule: 'One numerical character',
                    valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
                }
            ],
        }
    },
    watch: {},
    computed: {
        formIsValid() {
            return (
                this.mobileValid &&
                this.emailValid &&
                this.passwordValid &&
                this.PasswordReValid &&
                this.termCond 
            );
        },
        styleValidLname(){
            let style=""
            if(!this.form.last_name){
                 style = "border: 1px solid red !important;";
            } else{
                style = "border: 1px solid green !important;";
            }
            if(this.form.last_name==""){
                style = "border: 1px solid #ccc !important;";
            }
            return style;
        },
        styleValidFname(){
            let style=""
            if(!this.form.first_name){
                 style = "border: 1px solid red !important;";
            } else{
                style = "border: 1px solid green !important;";
            }
            if(this.form.first_name==""){
                style = "border: 1px solid #ccc !important;";
            }
            return style;
        },
        styleValidPassword(){
            var pattOne = /(?=.*[a-z])/;
            var pattTwo = /(?=.*[A-Z])/;
            var pattThree = /(?=.*\W)/;
            var pattFour = /[0-9]/;
            let style=""
            if(this.form.password==""){
                style = "border: 1px solid #ccc !important;";
            } else {
                if(this.form.password.length < 8){
                    style = "border: 1px solid red !important;";
                } 
                if(!pattOne.test(this.form.password)){
                    style = "border: 1px solid red !important;";
                }
                if(!pattTwo.test(this.form.password)){
                    style = "border: 1px solid red !important;";
                }   
                if(!pattThree.test(this.form.password)){
                    style = "border: 1px solid red !important;";
                }
                if(!pattFour.test(this.form.password)){
                    style = "border: 1px solid red !important;";
                }   
                if(pattFour.test(this.form.password) && pattThree.test(this.form.password) && pattTwo.test(this.form.password) &&
                    pattOne.test(this.form.password) && this.form.password.length >= 8
                    )
                {
                    style = "border: 1px solid green !important;";
                }          
                    
            }
            return style;
        },

        styleValidPasswordRe(){
            var pattOne = /(?=.*[a-z])/;
            var pattTwo = /(?=.*[A-Z])/;
            var pattThree = /(?=.*\W)/;
            var pattFour = /[0-9]/;
            let style=""
            if(this.password_two==""){
                style = "border: 1px solid #ccc !important;";
            } else {
                if(this.password_two.length < 8){
                    style = "border: 1px solid red !important;";
                } 
                if(!pattOne.test(this.password_two)){
                    style = "border: 1px solid red !important;";
                }
                if(!pattTwo.test(this.password_two)){
                    style = "border: 1px solid red !important;";
                }   
                if(!pattThree.test(this.password_two)){
                    style = "border: 1px solid red !important;";
                }   

                if(!this.form.password === this.password_two){
                    style = "border: 1px solid red !important;";
                }   
                if(!pattFour.test(this.form.password)){
                    style = "border: 1px solid red !important;";
                } 
                if(pattFour.test(this.form.password) && pattThree.test(this.password_two) && pattTwo.test(this.password_two) &&
                    pattOne.test(this.password_two) && this.password_two.length >= 8 && this.form.password === this.password_two
                    )
                {
                    style = "border: 1px solid green !important;";
                }          
                    
            }
            return style;
        }
    },
    beforemounted() {
        this.$nextTick(function () { 
               const mobileInput = document.querySelector(".input-tel__input");
               mobileInput.style.boxShadow = "none";
               mobileInput.placeholder = "";
               mobileInput.style.cursor = "auto";
               mobileInput.nextElementSibling.style.display = "none";
        });
    },
    methods:{
        loginWithFirebase: async function(authProvider) {
            let self = this; 
            if (authProvider === 1) {
                var provider = new firebase.auth.GoogleAuthProvider();
            } else if (authProvider === 2) {
                var provider = new firebase.auth.FacebookAuthProvider();
            }
            firebase.auth().signInWithPopup(provider).then(function(result) { 
                firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                self.loginWithFirebaseFun(authProvider,idToken);
                }).catch(function(error) { 
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Error in login.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                 }); 
            }).catch(function(error) { 
                var errorCode = error.code;
                var errorMessage = error.message; 
                var email = error.email; 
                var credential = error.credential; 
            });
        },
        loginWithFirebaseFun: async function(authProvider,idToken){
            try {
                let loginobj = { 
                "login_type": 1,
                "login_method": authProvider,
                "token": idToken
                }
                let response = await this.firebaseLogin(loginobj);
                if (response.status === 200) {
                // let name = "";
                // name = response.data.first_name + " " + response.data.last_name;
                this.$emit("closeModal");
                this.$store.commit("updateBuyerName", response.data);
                localStorage.setItem("buyer_token", response.data.token);
                localStorage.setItem("buyer_type", 'buyer');
                localStorage.setItem("login_buyer", response.data._id);
                localStorage.setItem("buyer_data", JSON.stringify(response.data));
                this.$store.dispatch("doLoginbuyer");
                this.$swal.fire({
                    position: 'top-right',
                    type: 'success',
                    title: "Logged In!",
                    showConfirmButton: false,
                    timer: 1500
                })
                // this.$router.push({ name: "Buyer Home" });
                
                }
                if(response.data._id !== ""){
                this.$emit("closeModal");
                this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: "Logged In!",
                    showConfirmButton: false,
                    timer: 1500
                })
                }
            } catch (error) {
                this.$swal.fire({
                position: 'center',
                type: 'error',
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500
                })      
            }
        },
        handleOpenNewTab() {
            // var myWindow = window.open("", "MsgWindow");
            // myWindow.document.write("<p>Cochchi.lk</p>");Shows an error unless an email in the form "xxxxx@xxxx.xxxx" is entered.
        },
        validateEmail(str) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(str).toLowerCase()) === true) { 
                this.changeColor1.border = "1px solid green";
                this.emailValid = true;
                this.emailError = ""
            } else {
                this.changeColor1.border = "1px solid red";
                this.emailValid = false;
                this.emailError = "Enter valid email. Ex: 'xxxxx@xxxx.xxxx'"
            }
            if (str === "") {
                this.changeColor1.border = "1px solid #ccc";
                this.emailError = ""
            }
        },
        setCountryCode(key) {
            if (key == null) {
                this.countryCode = key.countryCallingCode;
                this.dialCode = key.countryCode;
                this.mobile = key.formatNational;   
            }

        },
        validatePhone(key) {

            // mobileInput = document.querySelector("#mobileInput");
            // mobileInput.style.height = "auto";
            // mobileInput.style.boxShadow = "none";
            if(key == null || key == undefined){
                this.form.mobile = ""
            } else {
                if(this.countryCode==94){
                    if(key === "0"){
                        this.form.mobile = ""
                    }
                    key = key.replace(/^0+/, '')
                    key = key.replace(/ /g, "");
                    this.form.mobile = key;
                    if (key.length == 9) {
                    
                        // this.changeColor.border = "1px solid red";
                        //  mobileInput.style.border = "1px solid #ccc";
                        this.mobileValid = true;
                    } else if (key.length === 10 || key.length < 10) {
        
                        // this.changeColor.border = "1px solid #ccc";
                        //  mobileInput.style.border = "1px solid red";
                        //  mobileInput.style.borderRadius = "4px";
                        this.mobileValid = false;
                    }
                } else{
                    // key = key.replace(/^0+/, '');
                    if(key.length>15 || key.length<9){
                        this.mobileValid = false;    
                    } else{
                        this.mobileValid = true;
                    }
                    this.form.mobile = key;
                }
            }

        },
        validatePassword() {
            var pattOne = /(?=.*[a-z])/;
            var pattTwo = /(?=.*[A-Z])/;
            var pattThree = /(?=.*\W)/;
            var pattFour = /[0-9]/;
            // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

            if(pattOne.test(this.form.password)==true){
                this.valRuleArray[1].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArray[1].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if(pattTwo.test(this.form.password)==true){
                this.valRuleArray[2].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArray[2].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if(pattThree.test(this.form.password)==true){
                this.valRuleArray[3].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArray[3].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if(pattFour.test(this.form.password)==true){
                this.valRuleArray[4].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArray[4].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if((this.form.password.length >= 8)==true){
                
                this.valRuleArray[0].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArray[0].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if(pattOne.test(this.form.password)==true && pattTwo.test(this.form.password)==true && pattThree.test(this.form.password)==true && pattFour.test(this.form.password)==true && this.form.password.length >= 8){
                this.passwordValid = true;
                this.valConfPassword()
            }else{
                this.passwordValid = false;
            }

            // if(this.form.password.length <= 8 ){
            //     this.passwordValid = false;
            // }else{
            //     this.passwordValid = true;
            // }
        },

        valConfPassword: function() {
            var pattOne = /(?=.*[a-z])/;
            var pattTwo = /(?=.*[A-Z])/;
            var pattThree = /(?=.*\W)/;
            var pattFour = /[0-9]/;
            // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

            if (pattOne.test(this.password_two) == true) {
                this.valRuleArrayConf[1].valIcon =
                '<i style="color: green" class="fa fa-check-circle"></i>';
            } else {
                this.valRuleArrayConf[1].valIcon =
                '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if (pattTwo.test(this.password_two) == true) {
                this.valRuleArrayConf[2].valIcon =
                '<i style="color: green" class="fa fa-check-circle"></i>';
            } else {
                this.valRuleArrayConf[2].valIcon =
                '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if (pattThree.test(this.password_two) == true) {
                this.valRuleArrayConf[3].valIcon =
                '<i style="color: green" class="fa fa-check-circle"></i>';
            } else {
                this.valRuleArrayConf[3].valIcon =
                '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if (this.password_two.length >= 8 == true) {
                this.valRuleArrayConf[0].valIcon =
                '<i style="color: green" class="fa fa-check-circle"></i>';
            } else {
                this.valRuleArrayConf[0].valIcon =
                '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if (this.form.password === this.password_two) {
                this.valRuleArrayConf[4].valIcon =
                '<i style="color: green" class="fa fa-check-circle"></i>';
            } else {
                this.valRuleArrayConf[4].valIcon =
                '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if(pattFour.test(this.password_two)==true){
                this.valRuleArrayConf[5].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
            }else{
                this.valRuleArrayConf[5].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
            }
            if (
                pattOne.test(this.password_two) == true &&
                pattTwo.test(this.password_two) == true &&
                pattThree.test(this.password_two) == true &&
                pattFour.test(this.password_two) == true &&
                this.password_two.length >= 8 &&
                this.form.password === this.password_two
            ) {
                this.PasswordReValid = true;
            } else {
                this.PasswordReValid = false;
            }
        },
        verifyEvent(val) {
            if (val !== undefined && val !== "") {
                this.disable = false;
            } else {
                this.disable = true;
            }
        },
        proseedClick: async function() {
            try {
                if (this.news_feed === true) {
                    this.form.news_feed_status = 2;
                    this.form.news_feed_method = "111";
                } else {
                    this.form.news_feed_status = 3;
                    this.form.news_feed_method = "111";
                }

                let uemail = this.form.email;
                this.form.email = uemail.toLowerCase();
                
                let finalobj = JSON.parse(JSON.stringify(this.form));

                let countrcode = "+" + this.countryCode + this.form.mobile.replace(/^0+/, '');
                
                finalobj.mobile = countrcode.replace(/ /g, "");
                finalobj.dial_code = this.dialCode;
                finalobj.county_code = "+" + this.countryCode;
                let status = await this.buyerRegister(finalobj);
                if(status.data._id !== ""){ 
                    this.$parent.resetOTPtimer();
                    document.getElementById("tabView").style.display = "none";
                    document.getElementById("modalOTP").style.display = "block";
                    this.$swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Successfully Registered!',
                        showConfirmButton: false,
                        timer: 1500
                    })
 
                    localStorage.setItem("OTP_Buyer_Id", status.data._id);
                    let actionType = "register";
                    this.$emit("buyerIdOTP", status.data._id, status.data.mobile, actionType);
                    this.form.mobile = "";
                    this.form.email = "";
                    this.form.password = "";
                    }
                    
            } catch (error) {
                if(error.data.code === 408){
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Email already registered with an account',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else{
                    if(error.data.code === 409){
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Mobile number already registered with an account',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }else {
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: error.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }                             
                } 
            }
        },
        togglePwd: function() {
            if(this.$refs.inputRegPwd.type==="password"){
                this.$refs.inputRegPwd.type = "text";
                this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
            }else{
                this.$refs.inputRegPwd.type = "password";
                this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
            }
        },
        togglePwdRe: function() {
            if(this.$refs.inputRegPwdRe.type==="password"){
                this.$refs.inputRegPwdRe.type = "text";
                this.$refs.toggleIconRe.innerHTML = `<i class="fa fa-eye-slash"></i>`;
            }else{
                this.$refs.inputRegPwdRe.type = "password";
                this.$refs.toggleIconRe.innerHTML = `<i class="fa fa-eye"></i>`;
            }
        }
    }
}
</script>
<style scoped>
    #MazPhoneNumberInput {
        border: none !important;
    }
    #modalsocialregrow {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
        padding: 20px 0px;
    }
    #regForm {
        color: black;
    }
    #regForm label{
        font-size: 14px;
        color: black;
    }
    .username-ins{
        font-size: 8px;

    }
    #regForm .input-password input[type="password"]{
        position: relative;
    }
    #regForm .input-password span.toggleIcon{
        position: relative;
        display: block;
        float: right;
        top: -31px;
        right: 7px;
        cursor: auto;
    }
    #regForm .input-password .fa{
        font-size: 15px;
    }
    .buyerreg-btn{
        background: #5779ae;
        color: #ffffff;
        border: none;
    }
    .buyerreg-btn:hover{
        background: #000000;
    }
    .input-tel__label{
        display: none !important;
    }
    .input-tel__input{
        cursor: none !important;
    }
    .input-tel__label:hover{
         cursor: none !important;
    }
    .input-tel__input:hover{
        cursor: none !important;

    }
</style>
