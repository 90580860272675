<template>
  <div>
    <div id="signForm">
      <div class="switch-div">
        <div class="customToggel">
          <ul>
            <li
              @click="changeType('E-Mail')"
            >
              <label
                :class="switchVal === 'E-Mail'?'activeLbl':''"
              >E-Mail</label>
            </li>
            <li
              @click="changeType('Mobile')"
            >
              <label
                :class="switchVal === 'Mobile'?'activeLbl':''"
              > Mobile </label>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="switchVal === 'E-Mail'" class="form-group">
        <label for="email">Email :</label>
        <input
          v-model="form.username"
          required
          @input="validateEmail(form.username)"
          :style="changeColor1"
          type="email"
          class="form-control"
          v-on:keyup.enter="onEnter()"
          id="text"
        />
      </div>
      <div v-if="switchVal === 'Mobile'" class="form-group">
        <label for="Mobile">Mobile :</label>
        <VuePhoneNumberInput
          v-if="this.switchVal === 'Mobile'"
          valid-color="green"
          error-color="red"
          v-model="mobile"
          @update="validateMobile"
          :only-countries="['LK']"
          default-country-code="LK"
          :translations="{ phoneNumberLabel: '' }"
          no-example
          style="height: 38px; font-size: 18px; line-height: 1.3333333; border-radius: 6px;cursor:auto;"
          id="mobileInput"
        />
        <!-- <input
          v-model="form.username"
          required
          @input="validateEmail(form.username)"
          :style="changeColor1"
          type="email"
          class="form-control"
          v-on:keyup.enter="onEnter()"
          id="text"
        /> -->
      </div>
      <div class="form-group input-password">
        <label for="pwd">Password :</label>
        <input
          v-model="form.password"
          :style="styleValidPassword"
          required
          type="password"
          class="form-control pw-field"
          v-on:keyup.enter="onEnter()"
          id="pwd"
          @input="validatePassword"
          ref="inputLgnPwd"
        />
        <span @click="togglePwd()" ref="toggleIcon">
          <i class="fa fa-eye"></i>
        </span>
        <label v-show="!passwordValid && form.password != ''" style>
          <!-- <span
            style="color: grey;font-size:10px;margin-right:10px;top: 10%; right: auto;margin-top: 10px;"
          >
            <span v-for="(vrule,index) in valRuleArray" :key="vrule.index">
              {{vrule.valRule}}
              <span style="top: 10%; right: auto; margin-right:10px;" v-html="vrule.valIcon"></span>
              {{valRuleArray.length-1!=index?', ':''}}
            </span>
          </span>-->
        </label>
      </div>
      <button
        :disabled="
          styleValidPassword != 'border: 1px solid green !important;' ||
          changeColor1.border === '1px solid red'||
            disableBtn
        "
        id="btnlogin"
        @click="submit"
        style="margin-top: 0px; border-radius: 5px; font-weight: 600;"
        class="btn sign-reg-btn btn-block"
      >
        Login
      </button>
      <div>
        <label style="color: black; margin-top: 10px;">
          <a id="fogotPw" @click="forgotPass()">Forgot Password ?</a>
        </label>
      </div>
    </div>

    <img class="img-responsive"  :src="this.$store.state.colourLogo" style="width: 50%; right: 25%;bottom: 15px;position: absolute;">

    <div class="row">
      <div class="col-md-8 col-sm-8" id="modalsocialrow" >
          <small>Quck Accsess with </small>
          <a>
              <img src="assets/image/buyer_modal/google.png" @click="loginWithFirebase(1)" title="Google Login">
          </a>
          <a>
              <img src="assets/image/buyer_modal/facebook.png" @click="loginWithFirebase(2)" title="Facebook Login">
          </a>
      </div>
    </div>
  </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";
import mixinProfile from "@/mixins/buyer/APIProfile";
import firebase from "firebase";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  mixins: [mixinAuth, mixinProfile],
  components: {
    VuePhoneNumberInput
  },
  props: {},
  data() {
    return {
      disableBtn: false,
      changeColor1: {
        border: "1px solid #ccc",
      },
      passwordValid: false,
      form: {
        username: "",
        password: "",
        user_type: 2,
      },
      switchVal: "E-Mail",
      valRuleArray: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
      ],
      loginWithEmail: false,
      mobile:"",
    };
  },
  computed: {
    formIsValid() {
      return this.form.username && this.form.password;
    },
    styleValidPassword() {
      // var pattOne = /(?=.*[a-z])/;
      // var pattTwo = /(?=.*[A-Z])/;
      // var pattThree = /(?=.*\W)/;
      let style = "";
      if (this.form.password == "") {
        style = "border: 1px solid #ccc !important;";
      } else {
        // if (this.form.password.length  1) {
        //   style = "border: 1px solid red !important;";
        // } else{
        style = "border: 1px solid green !important;";
        // }
        // if (!pattOne.test(this.form.password)) {
        //   style = "border: 1px solid red !important;";
        // }
        // if (!pattTwo.test(this.form.password)) {
        //   style = "border: 1px solid red !important;";
        // }
        // if (!pattThree.test(this.form.password)) {
        //   style = "border: 1px solid red !important;";
        // }
        // if (
        //   pattThree.test(this.form.password) &&
        //   pattTwo.test(this.form.password) &&
        //   pattOne.test(this.form.password) &&
        //   this.form.password.length >= 8
        // ) {
        //   style = "border: 1px solid green !important;";
        // }
      }
      return style;
    },
  },
  methods: {
    validateEmail(str) {
      var regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (regx.test(str)) {
        this.changeColor1.border = "1px solid green";
      } else {
        this.changeColor1.border = "1px solid red";
      }
      if (str === "") {
        this.changeColor1.border = "1px solid #ccc";
      }
    },
    validateMobile: function(payload) {
      // if (this.mobile.length === 13 && payload.formattedNumber.length === 12 && this.mobile === payload.formattedNumber) {
      //   console.log("true")
      // }
      // this.countryCodeMoblie = payload.countryCode;
      // this.dial_code = "+" + payload.countryCallingCode;
      // this.mobile = payload.formattedNumber;
      if (payload.formattedNumber) {
        this.form.username = payload.formattedNumber;
        if (payload.formattedNumber.length === 12) {
          this.disableBtn = false;
        } else {
          this.disableBtn = true;
        }
      } else {
        this.disableBtn = true;
      }
    },
    validatePassword() {
      // var pattOne = /(?=.*[a-z])/;
      // var pattTwo = /(?=.*[A-Z])/;
      // var pattThree = /(?=.*\W)/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;
      // if (pattOne.test(this.form.password) == true) {
      //   this.valRuleArray[1].valIcon =
      //     '<i style="color: green" class="fa fa-check-circle"></i>';
      // } else {
      //   this.valRuleArray[1].valIcon =
      //     '<i style="color: red" class="fa fa-times-circle"></i>';
      // }
      // if (pattTwo.test(this.form.password) == true) {
      //   this.valRuleArray[2].valIcon =
      //     '<i style="color: green" class="fa fa-check-circle"></i>';
      // } else {
      //   this.valRuleArray[2].valIcon =
      //     '<i style="color: red" class="fa fa-times-circle"></i>';
      // }
      // if (pattThree.test(this.form.password) == true) {
      //   this.valRuleArray[3].valIcon =
      //     '<i style="color: green" class="fa fa-check-circle"></i>';
      // } else {
      //   this.valRuleArray[3].valIcon =
      //     '<i style="color: red" class="fa fa-times-circle"></i>';
      // }
      // if (this.form.password.length >= 8 == true) {
      //   this.valRuleArray[0].valIcon =
      //     '<i style="color: green" class="fa fa-check-circle"></i>';
      // } else {
      //   this.valRuleArray[0].valIcon =
      //     '<i style="color: red" class="fa fa-times-circle"></i>';
      // }
      if (
        // pattOne.test(this.form.password) == true &&
        // pattTwo.test(this.form.password) == true &&
        // pattThree.test(this.form.password) == true &&
        this.form.password
      ) {
        this.passwordValid = true;
      } else {
        this.passwordValid = false;
      }

      // if(this.form.password.length <= 8 ){
      //     this.passwordValid = false;
      // }else{
      //     this.passwordValid = true;
      // }
    },
    changeType: function(val) {
      this.switchVal = val;
      this.mobile = "";
      this.form.username = "";
      this.form.password = "";
    },
    forgotPass() {
      this.$emit("closeModal", "forgetPass");
    },
    onEnter() {
      document.getElementById("btnlogin").click();
    },
    submit: async function() {
      try {
        this.disableBtn = true;
        let uname = this.form.username;
        this.form.username = uname.toLowerCase();

        // Check login with mobile or email
        if (this.form.username.includes("@")) {
          this.loginWithEmail = true;
        } else {
          this.loginWithEmail = false;
        }

        let response = await this.buyerLogin(this.form);

        if (response.status === 200) {
          // let name = "";
          // name = response.data.first_name + " " + response.data.last_name;

          // Check mobile verification
          if (response.data.mobile_verify == true) {
            // check email verification
            if (this.loginWithEmail == false) {
              this.$store.commit("updateBuyerName", response.data);
              localStorage.setItem("buyer_token", response.data.token);
              localStorage.setItem("buyer_type", "buyer");
              localStorage.setItem("login_buyer", response.data._id);
              localStorage.setItem("buyer_data", JSON.stringify(response.data));

              if (response.data.hasOwnProperty("city")) {
                localStorage.setItem("user_city", response.data.city);
              }
              if (response.data.hasOwnProperty("city_name")) {
                localStorage.setItem("user_city_name", response.data.city_name);
              }
              this.$store.dispatch("doLoginbuyer");
              this.$swal.fire({
                position: "top-right",
                type: "success",
                title: "Logged In!",
                showConfirmButton: false,
                timer: 1500,
              });
              // this.$router.push({ name: "Buyer Profile" });
                // if(this.$parent.showCompleteProfie === false){
                //   this.$emit("closeModal");
                // }
              this.handleContinue();
            } else {
              if (response.data.email_verify == true) {
                this.$store.commit("updateBuyerName", response.data);
                localStorage.setItem("buyer_token", response.data.token);
                localStorage.setItem("buyer_type", "buyer");
                localStorage.setItem("login_buyer", response.data._id);
                localStorage.setItem(
                  "buyer_data",
                  JSON.stringify(response.data)
                );

                if (response.data.hasOwnProperty("city")) {
                  localStorage.setItem("user_city", response.data.city);
                }
                if (response.data.hasOwnProperty("city_name")) {
                  localStorage.setItem(
                    "user_city_name",
                    response.data.city_name
                  );
                }
                this.$store.dispatch("doLoginbuyer");
                this.$swal.fire({
                  position: "top-right",
                  type: "success",
                  title: "Logged In!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                // this.$router.push({ name: "Buyer Profile" });
                // if(this.$parent.showCompleteProfie === false){
                //   this.$emit("closeModal");
                // }
                this.handleContinue();
              } else {
                this.$swal.fire({
                  position: "center",
                  type: "error",
                  title: "Please verify your email!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
          } else {
            if (response.data._id !== "") {
              let res = await this.buyerMobileResend(response.data._id);
              this.$parent.resetOTPtimer();
              document.getElementById("tabView").style.display = "none";
              document.getElementById("modalOTP").style.display = "block";
              localStorage.setItem("OTP_Buyer_Id", response.data._id);
              let actionType = "login";
              this.$emit(
                "buyerIdOTP",
                response.data._id,
                response.data.mobile,
                actionType
              );
            }
          }
        }
        this.disableBtn = false;
        // if (response.data._id !== "") {
        //   this.$swal.fire({
        //     position: "center",
        //     type: "success",
        //     title: "Logged In!",
        //     showConfirmButton: false,
        //     timer: 1500
        //   });
        // }
        // await this.reloardWindow();
      } catch (error) {
        this.disableBtn = false;
        if (error.data.message == "Username or password incorrect") {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Incorrect User ID or Password. Please try again.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    reloardWindow() {
      location.reload();
    },
    closeModalProfile() {
      this.$parent.closeModalCompleteProfile();
    },
    handleContinue: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        if (
          response.addresses.length == 0 ||
          response.first_name == undefined ||
          response.last_name == undefined
        ) {
          this.$parent.showCompleteProfie = true;
          if (response.user_role != 2) {
            this.$parent.showModal();
          }

          this.$parent.showforgotpass = false;
          setTimeout(this.closeModalProfile, 30000);
          // this.$swal({
          //     title: 'Please add personal profile information',
          //     text: "",
          //     type: 'warning',
          //     showCancelButton: true,
          //     confirmButtonColor: '#3085d6',
          //     confirmButtonText: 'Go to profile',
          //     cancelButtonText: 'Skip'
          // }).then((result) => {
          //      if (result.value) {
          //         this.$router.push({ name: "Buyer Profile" });
          //     }
          // });
        } else {
          this.$emit("closeModal");
        }
        // location.reload(); // Commented on 02-11-2020
      } catch (e) {
        throw e;
      }
    },
    loginWithFirebase: async function(authProvider) {
      var self = this;
      if (authProvider === 1) {
        var provider = new firebase.auth.GoogleAuthProvider();
      } else if (authProvider === 2) {
        var provider = new firebase.auth.FacebookAuthProvider();
      }
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function(idToken) {
              self.loginWithFirebaseFun(authProvider, idToken);
            })
            .catch(function(error) {
              this.$swal.fire({
                position: "center",
                type: "error",
                title: "Error in login.",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    },
    loginWithFirebaseFun: async function(authProvider, idToken) {
      try {
        let loginobj = {
          login_type: 1,
          login_method: authProvider,
          token: idToken,
        };
        let response = await this.firebaseLogin(loginobj);
        if (response.status === 200) {
          // let name = "";
          // name = response.data.first_name + " " + response.data.last_name;
          this.$store.commit("updateBuyerName", response.data);
          localStorage.setItem("buyer_token", response.data.token);
          localStorage.setItem("buyer_type", "buyer");
          localStorage.setItem("login_buyer", response.data._id);
          localStorage.setItem("buyer_data", JSON.stringify(response.data));
          this.$store.dispatch("doLoginbuyer");
          this.$swal.fire({
            position: "top-right",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.$router.push({ name: "Buyer Home" });
          if(this.$parent.showCompleteProfie === false){
            this.$emit("closeModal");
          }
        }
        if (response.data._id !== "") {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    togglePwd: function() {
      if (this.$refs.inputLgnPwd.type === "password") {
        this.$refs.inputLgnPwd.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputLgnPwd.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
  },
};
</script>
<style>
#modalsocialrow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  padding: 25px 0px;
}
#signForm {
  padding-bottom: 43px;
  border-bottom: none !important;
}
#signForm label {
  font-size: 14px;
  color: black;
}
#fogotPw:hover {
  color: #ffd338;
}
#signForm .input-password input[type="password"] {
  position: relative;
}
#signForm .input-password span {
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#signForm .input-password .fa {
  font-size: 15px;
}
.sign-reg-btn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.sign-reg-btn:hover {
  background: #000000;
  color: #ffffff;
}
.pw-field {
  position: relative;
  display: block;
  float: right;
  cursor: auto;
}
.pw-field-font {
  font-size: 15px;
}

.switch-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.customToggel ul {
  display: inline-flex;
  list-style: none;
  box-sizing: border-box;
  width: 22rem;
  height: 4rem;
  padding: 0;
  margin: 0;
}

.customToggel li{
  position: relative;
  text-align: center;
  font-family: inherit;
  list-style: none;
  width: 22rem;
  height: 4.4rem;
  font-size: 18rem;
  padding: 0;
}

.customToggel label{
  padding: 0.7rem;
  border-color: black;
  background-color: white;
  color: black;
  font-weight: normal;
  transition: all 0.4s ease 0s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.customToggel .activeLbl {
  background-color: rgb(17, 75, 134);
  color: white !important;
  font-weight: bold;
} 

.customToggel li:last-child label {
  border-color: black !important;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.customToggel li:first-child label {
  border-color: black !important;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
